import React from 'react';
import Image from 'next/image';
import Link from 'next/link';

import Logo from 'assets/landing/logo.svg';
import Text from 'components/Text';
import LoginForm from 'layouts/Auth/LoginForm';
import staticPage from 'lib/next/staticPage';
import { AuthRole } from 'types/enums';

import * as Styled from './styles';

const isBrandsApp = !!process.env.NEXT_PUBLIC_BRANDS_APP;

const Login = () => {
  return (
    <Styled.Layout>
      <Styled.Header>
        {isBrandsApp ? (
          <Text variant="h5">
            Don’t have a brand account? <Link href="/set-up/signup">Sign up</Link>
          </Text>
        ) : (
          <Text variant="h5">
            Don’t have an athlete account? <Link href="/set-up/signup">Sign up</Link>
          </Text>
        )}
      </Styled.Header>

      <Styled.Content>
        <Image alt="Parity" src={Logo} width={72} height={72} />
        {isBrandsApp ? (
          <Styled.Title as="h1" variant="h1">
            Sign into your <b>Brand Dashboard</b>
          </Styled.Title>
        ) : (
          <Styled.Title as="h1" variant="h1">
            Sign into your <b>Athlete Dashboard</b>
          </Styled.Title>
        )}
        <Styled.Card>
          <LoginForm />
        </Styled.Card>
      </Styled.Content>
    </Styled.Layout>
  );
};

export default staticPage({
  header: false,
  role: AuthRole.Anonymous,
  title: 'Login',
})(React.memo(Login));
