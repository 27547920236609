import styled from '@emotion/styled';

import LayoutContainer from 'components/LayoutContainer';
import Text from 'components/Text';
import { respondTo } from 'styles';

export const Layout = styled(LayoutContainer)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 0 16px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 40px 0;

  a:last-child {
    color: ${(props) => props.theme.brand.secondary};
    font-weight: 600;
  }
`;

export const Title = styled(Text)`
  margin: 30px auto 40px auto;
  text-align: center;

  ${respondTo.mobile} {
    margin: 20px auto 30px auto;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  padding: 40px 0;

  ${respondTo.mobile} {
    padding: 0 0 40px 0;
  }
`;

export const Card = styled.div`
  background-color: ${(props) => props.theme.surface.primary};
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding: 40px;
  width: 100%;
  max-width: 484px;
  margin: 0 auto;
`;
