import styled from '@emotion/styled';

import typography from 'styles/typography';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 480px;
  width: 100%;
  margin: 0 auto;

  & > section:not(:last-of-type) {
    margin-bottom: 16px;
  }

  & > section:last-of-type {
    margin-bottom: 0;
  }

  a {
    ${typography.body2};
    margin: 16px auto 32px auto;
    text-align: center;
    color: ${(props) => props.theme.foreground.primary};
  }
`;
