import React from 'react';
import Link from 'next/link';
import Router from 'next/router';

import Email from 'assets/email/email-foreground-primary.svg';
import Password from 'assets/password/password-foreground-primary.svg';
import Button from 'components/Button';
import TextInput from 'components/TextInput';
import useUser from 'data/useUser';
import useForm from 'hooks/useForm';
import request, { matchError } from 'lib/request';
import { FormError, User } from 'types';

import * as Styled from './styles';

interface FormValues extends FormError {
  email: string;
  password: string;
}

const LoginForm = () => {
  const { mutate } = useUser({
    revalidateOnMount: false,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const methods = useForm<FormValues>();
  const { formState, handleSubmit, register, control, setError } = methods;
  const { errors, isSubmitting } = formState;

  const onSubmit = handleSubmit(async (body) => {
    const response = await request<User>('login', {
      body,
      method: 'post',
    });

    if (matchError(response)) {
      response.mapFormErrors(control, setError);
    } else {
      mutate(response.payload, false);

      Router.replace('/dashboard');
    }
  });

  return (
    <Styled.Form onSubmit={onSubmit}>
      <TextInput
        {...register('email', {
          required: true,
        })}
        label="Email"
        icon={Email}
        iconAlt="Email icon."
        placeholder="Enter your email address"
        error={errors.email}
        type="email"
        autoComplete="email"
        required
      />
      <TextInput
        {...register('password', {
          required: true,
        })}
        label="Password"
        icon={Password}
        iconAlt="Password icon."
        placeholder="Enter your password"
        error={errors.password}
        type="password"
        autoComplete="current-password"
        required
      />
      <Link href="/reset-password" prefetch={false} passHref>
        Forgot password?
      </Link>
      <Button data-testId="Login_submit" variant="secondary" size="big" type="submit" loading={isSubmitting}>
        Sign In
      </Button>
    </Styled.Form>
  );
};

export default React.memo(LoginForm);
